import React from 'react';
import Layout from '../../components/Layout';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import Colors from '../../components/Colors';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';

const TagsPage = ({
  data: {
    allMdx: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout
    jumbo={true}
    pageTitle="Christian TV & Movies Reviews & Discussion"
    pageDescription="Reviews & Discussions about Christian TV & Movies. Put away things of the world and fill your life with things of God."
  >
    <h1
      css={css`
        color: ${Colors.main} !important;
        font-size: 2rem;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 2rem;
        border-bottom: 2px solid ${Colors.main};
      `}
    >
      <FontAwesomeIcon
        css={css`
          color: ${Colors.main} !important;
          margin-right: 0.5rem;
        `}
        icon={faHashtag}
        size={'sm'}
        width="28"
      />
      TOPICS
    </h1>
    <div css={css`margin-bottom: 2rem; text-align: center;`}>Below is a list of topics for the articles we have on Christian Bytes. Select a topic to see all of the articles.</div>
    <ul
      css={css`
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        justify-content: center;
      `}
    >
      {group.map(tag => (
        <li key={tag.fieldValue}>
          <Link
            css={css`
              background: #dedede;
              padding: 0.15em 15px;
              margin: 0 5px 0.25em 0;
              color: #484848;
              border-radius: 6px;
              font-size: 0.8rem;
              -webkit-letter-spacing: 0.2rem;
              -moz-letter-spacing: 0.2rem;
              -ms-letter-spacing: 0.2rem;
              letter-spacing: 0.2rem;
              -webkit-text-decoration: none;
              text-decoration: none;
              text-transform: uppercase;
            `}
            to={`/topics/${tag.fieldValue.replace(/\s+/g, '-').toLowerCase()}/`}
          >
            {tag.fieldValue}
          </Link>
        </li>
      ))}
    </ul>
  </Layout>
);
export default TagsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
